<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import StarRating from "vue-star-rating";
import VueEasyLightbox from "vue-easy-lightbox";
import { apiRequest } from "@/helpers/api-call";
//import {reactive} from "vue";
import { format } from "date-fns";

import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";
//import { tableData } from "../tables/dataAdvancedtable";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Détails trajets effectués",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, VueEasyLightbox },
  data() {
    return {
      title: "Détails trajets effectués",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "details",
          active: true,
        },
      ],
       totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5,10, 25],
      filter: null,
      filterOn: [],
      date: "",
      dateDepart: "",
      dateArrive: "",
      tableData: [],
      passagerId: [],
      dataUser:[],
      details: "",
      sortBy: "orderid",
      sortDesc: false,
      conducteur: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label:"Départ" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "billingname", sortable: true, label: "Passagers" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      fields: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label:"Départ" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "passager", sortable: true, label: "Passagers" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      passager: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label:"Départ" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "billingname", sortable: true, label: "Passagers" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      username:"",
      dataUserTable:[],
      avatar:"",
      tokenUser: JSON.parse(sessionStorage.getItem("user")).tokenSalt,
      idtrajet: "",
      fisrtname: "",
      lastname: "",
      genre: "",
      boite_postale: "",
      profession: "",
      birthday: "",
      adress: "",
      city: "",
      country: "",
      number_phone: "",
      email: "",
      password: "",
      showPassword: false,
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      index: 0,
      allImage: {
        avatar: "",
        permis: "",
        vehicule: "",
        cni: "",
      },
      img: "",
      vue: false,
      visible: false,
      showModal:false,
      HistoriqueConducteur:false,
      HistoriquePassager:false,
      picture: ["PROFIL", "CNI", "PERMIS", "VOITURE"],
      imgs: "",
    };
  },
  allImage: {
    avatar: "",
    permis: "",
    vehicule: "",
    cni: "",
  },
  validations: {
    firstname: { required },
    lastname: { required },
    genre: { required },
    number_phone: { required, numeric },
    email: { required, email },
    password: { required },

    tooltipform: {
      fname: { required },
      lname: { required },
      username: { required },
      city: { required },
      state: { required },
    },
    range: {
      minlen: { required, minLength: minLength(6) },
      maxlength: { required, maxLength: maxLength(6) },
      between: { required, minLength: minLength(5), maxLength: maxLength(10) },
      minval: { required, minValue: minValue(6) },
      maxval: { required, maxValue: maxValue(6) },
      rangeval: { required, minValue: minValue(6), maxValue: maxValue(100) },
      expr: { required },
    },
    typeform: {
      name: { required },
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
      email: { required, email },
      url: { required, url },
      digit: { required, numeric },
      number: { required, numeric },
      alphanum: { required, alphaNum },
      textarea: { required },
    },
  },
  async mounted() {
    //console.log("tokenUser:", this.tokenUser);
    this.idtrajet = this.$route.params.idtrajet;

    //Chargement des données
    console.log("Détails", this.idtrajet);
    console.log("Table", this.tableData);

    //"admin/trajets/activites/?id=" + this.idUser,
    const data = await apiRequest("GET", "trajet/?id=" + this.idtrajet, false);
    if (data && data.data) {
      console.log("Data", data.data);
      /*const formattedTable = data.data.map((trajet) => {
        return {
          id:trajet.id,
          itinéraire: trajet.lieuDepart.nom.split(',')[0] + " >> " + trajet.lieuArrivee.nom.split(',')[0],
          conducteur:
            trajet.conducteur.personne.lastname[0] +
            ". " +
            trajet.conducteur.personne.firstname,
          Depart: format(
            new Date(trajet.dateHeureDepart),
            "dd-MM-yyyy hh:mm:ss"
          ),
          nombrePlace: trajet.nombrePlace,
          prix: trajet.prix + " XOF",
          passagers: trajet.passagers.length
            trajet.passagers.map((passager) => {
            if (passager) {
              return {
                id:
                  passager.id
              };
            }
          }),
        };
      });*/
      this.tableData = data.data;
      (this.date = new Date(this.tableData.dateHeureDepart).toLocaleString(
        "fr-FR"
      )),
        (this.dateDepart = format(
          new Date(this.tableData.dateHeureDepart),
          "hh:mm:ss"
        ));
      this.dateArrive = format(
        new Date(this.tableData.dateHeureArrive),
        "hh:mm:ss"
      );

      if (data.data.conducteur.userAvatarCode !== null) {
        this.allImage.avatar =
          `http://216.158.239.208:1337/admin/image/?codeImage=` +
          data.data.conducteur.userAvatarCode +
          `&token=` +
          this.tokenUser;
      } else {
        this.allImage.avatar = require("@/assets/images/users/attente.png");
      }

      if (data.data.conducteur.vehicule.autoMotoPictureCode !== null) {
        this.allImage.permis =
          `http://216.158.239.208:1337/admin/image/?codeImage=` +
          data.data.conducteur.vehicule.autoMotoPictureCode +
          `&token=` +
          this.tokenUser;
      } else {
        this.allImage.permis = require("@/assets/images/users/attente.png");
      }
    }

     const Users = await apiRequest("GET", "admin/users", undefined, false);
      if (data && data.data) {
        //console.log("data.data:", data.data);
        const formattedTable = Users.data.map((user) => {
          var nom = user.lastname == "" ? "Nom" : user.lastname;
          var prenom = user.firstname == "" ? "Prénom" : user.firstname;
          return {
            id: user.id,
            username: nom + " " + prenom,
            firstname: user.firstname,
            lastname: user.lastname,
            pays: user.pays,
            tel: user.tel,
            trajet: user.nombreTrajet,
            dateIns: new Date(user.dateCreation).toLocaleString("fr-FR"),
            badge: user.userValidated,
            statut: user.active ? "Actif" : "Suspendu",
          };
        });
        this.dataUser = formattedTable;
        console.log("data", Users.data);
      }


    var lespassagers = data.data.passagers;
    console.log("Table", lespassagers);

    this.passagerId = [];
    lespassagers.forEach((element) => {
      if (element.statut == "ACTIF") {
        this.passagerId.push(element.idClient);
      }
    });

      for(let i=0; i<this.passagerId.length;i++){
        const Users = await apiRequest(
        "POST",
        "admin/detail-user",
        { id: this.passagerId[i] },
        false
      );
      
        if(Users && Users.data){
          console.log("detail Users", Users.data)
          this.dataUserTable.push(Users.data);
          console.log("dataUserTable", this.dataUserTable)
        //this.username = Users.data.personne.lastname+" "+ Users.data.personne.firstname

        
        }
      }


  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },

    verification: function() {
      console.log("ok");
    },

    ValidationStatus: function(validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    Submit: function() {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      alert("data submit");
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },

    showImage(img) {
      //console.log(img.srcElement.src)
      this.imgs = img.srcElement.src;
      console.log(this.imgs);
      this.show();
    },

    handleHide() {
      this.visible = false;
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    show() {
      this.visible = true;
    },

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <!-- <div class="col-md-12">
        <div class="card">
          <div class="card-body">
              <div class="d-flex justify-content-center">
                 Trajet effectué le: {{this.date}}
              </div>
              <hr style="width:40%;">
            <div class="row py-5">
              <div class="col-md-6">
                  <div class="">
                      <div>{{this.dateDepart}}<i class="fas fa-map-marker-alt text-primary ml-2 mr-1"></i>{{this.tableData.lieuDepart.nom}}</div>
                  </div>
                  <div class="mt-5">
                      <div>{{this.dateArrive}}<i class="fas fa-map-marker-alt text-danger ml-2 mr-1"></i>{{this.tableData.lieuArrivee.nom}}</div>
                  </div>
                  <div class="mt-5">
                      Siège disponible: {{this.tableData.nombrePlace}}
                  </div>
                  <div class="mt-5">
                     Taille de bagage: {{this.tableData.tailleBagageAutorise}}
                  </div>
              </div>
              <div class="col-md-6">
                  <div>
                     <div><i class="fas fa-calendar-day mr-3"></i>Mode de réservation : <span>{{this.tableData.modeReservation}}</span></div>
                  </div>
                  <div class="mt-5">
                     <div><i class="fas fa-search-location mr-2"></i> Point de rencontre : <span>{{this.tableData.pointDeRencontre.nom}}</span></div>
                  </div>
                  <div class="mt-5">
                     <div><i class="fas fa-stopwatch mr-3 "></i>flexibilité : <span>{{this.tableData.flexibilite}} min</span></div> 
                  </div>
                  <div class="mt-5">
                     <div><i class=""></i>Prix par passager : <span class="badge badge-success p-2">{{this.tableData.prix}} XOF</span></div> 
                  </div>
                  
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center"></div>
            <div class="row py-5">
              <div class="col-md-4">
                <div class="row">
                  <a class="image-popup-vertical-fit d-flex justify-content-center" @click="showImage">
                    <!-- class=" avatar-xl d-flex justify-content-center" -->
                    <img
                      style="cursor: pointer;"
                      class="avatar-xl"
                      :src="`${this.allImage.avatar}`"
                      data-holder-rendered="true"
                    />
                  </a>
                  <vue-easy-lightbox
                    :visible="visible"
                    :imgs="imgs"
                    @hide="handleHide"
                  ></vue-easy-lightbox>
                  <div class="mt-4">
                   <i class="fas fa-user align-middle mr-2 ml-2"></i> <span class="font-weight-bold"
                      >{{ this.tableData.conducteur.personne.firstname }}
                      {{ this.tableData.conducteur.personne.lastname }}</span
                    >
                    <div>
                    <i class="fas fa-comment mr-2 ml-2"></i><span>"{{ this.tableData.infosSup }}"</span>
                  </div>
                  </div>
                  
                </div>

                <div class="row">
                  <a class="image-popup-vertical-fit d-flex justify-content-center" @click="showImage">
                    <img
                      style="cursor: pointer;"
                      class="avatar-xl mt-5"
                      :src="`${this.allImage.permis}`"
                      data-holder-rendered="true"
                    />
                    <!-- <img class="img-time-matters" :src="require(`../assets/time-comparison-${locale}.png`)"> -->
                  </a>
                  <div class="mt-5">
                  <div class="mt-3">
                    <i class="fas fa-fan mr-2 ml-2"></i>Climatisation:
                    <span>{{
                      this.tableData.conducteur.vehicule.aClimatisation == false
                        ? "Non"
                        : "Oui"
                    }}</span>
                  </div>
                  <div class="">
                   <i class="fas fa-fan ml-2 mr-1"></i> Immatriculation :
                    <span
                      >{{
                        this.tableData.conducteur.vehicule
                          .autoMotoImmatriculation
                      }}
                      min</span
                    >
                  </div>
                  <div class="">
                    <i class="fas fa-palette ml-2 mr-2"></i> Couleur :
                    <span>{{
                      this.tableData.conducteur.vehicule.autoMotoCouleur
                    }}</span>
                  </div>
                </div>
                </div>
              </div>
              <span class="vertical-line"></span>
              <div class="" v-for="(item, index) in dataUserTable"
                  :key="index">
                  <div class="row">
                   <a class="image-popup-vertical-fit ml-5" @click="showImage"> 
                  <img
                    style="cursor: pointer; width:50px;"
                    class="avatar avatar-15 rounded-circle"
                    :src="`http://216.158.239.208:1337/admin/image/?codeImage=${item.userAvatarCode}&token=${tokenUser}`"
                    data-holder-rendered="true"
                  />
                  </a>
                  <!-- <img class="img-time-matters" :src="require(`../assets/time-comparison-${locale}.png`)"> -->
                
               <div class="ml-3">
                  {{ item.personne.firstname+" "+ item.personne.lastname }}
                  
                    <span class="fas fa-map-marker-alt text-primary ml-2 mr-1"></span>{{ tableData.lieuDepart.nom}}
                  
                  
                    <span class="fas fa-map-marker-alt text-danger ml-2 mr-1"></span>{{ tableData.lieuArrivee.nom}}
                  
                  
                    <span class="badge badge-success">{{ tableData.prix}} XOF</span>
                  
               </div>
                  </div>            
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </Layout>
</template>

<style>
label {
  font-size: 20px;
}
.divider {
  width: 100%;
  border: solid 2px blue;
}
.blueG {
  border: solid 1px #8608b8ad;
}
.YelG {
  border: solid 1px #ff6545;
}
.text-blueG {
  color: #8608b8ad;
}
.vertical-line {
  border-left: 2px solid #000;
  height: 430px;
  margin: 0 20px;
}
</style>
